import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'

import { Line } from 'react-chartjs-2';

const { forwardRef, useRef, useImperativeHandle } = React;

var initialCheckBoxesState = [
  {name:'1M', checked: false},
  {name:'3M', checked: false},
  {name:'6M', checked: false},
  {name:'1Y', checked: false},
  {name:'2Y', checked: true},
  {name:'3Y', checked: false},
  {name:'5Y', checked: false},
  {name:'7Y', checked: false},
  {name:'10Y', checked: true},
  {name:'20Y', checked: false},
  {name:'30Y', checked: false},
].reverse()


var colors = {
  '1M': '#99b5bd',
  '3M': '#D3D3D3',
  '6M': '#8cbcca',
  '1Y': '#86c0d0',
  '2Y': '#3c7e90',
  '3Y': '#758b42',
  '5Y': '#903C7E',
  '7Y': '#D0F1FA',
  '10Y': '#65d2f1',
  '20Y': '#FFD580',
  '30Y': '#FFA500'
};

//var initialCheckBoxesState = initialCheckBoxesStateOrig.reverse()

const SpreadsPanel = React.forwardRef ((props, ref) => {

  const chartRef = useRef();
  const spreadsRef = useRef();


  const [data, setData] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false,
  });

  const [checkBoxes, setCheckedBoxes] = useState(initialCheckBoxesState);
  const [tenorYields, setTenorYields] = useState(undefined);
  const [error, setError] = useState("");

  const handleCheckOnClick = (event) => {
    setError("");
    let checkBoxIndex = checkBoxes.findIndex(
      (box) => box.name === event.target.name
    );
    let copyOfCheckBoxes = [...checkBoxes];
    let isChecked = copyOfCheckBoxes[checkBoxIndex].checked;
    let amountOfCheckedBoxes = checkBoxes.filter((box) => box.checked);

    if (!isChecked && amountOfCheckedBoxes.length >= 2) {
      setError("Max of 2 checked checkboxes");
      return;
    }

    if (isChecked) {
      copyOfCheckBoxes[checkBoxIndex] = {
        ...copyOfCheckBoxes[checkBoxIndex],
        checked: false
      };
    } else {
      copyOfCheckBoxes[checkBoxIndex] = {
        ...copyOfCheckBoxes[checkBoxIndex],
        checked: true
      };
    }

    setCheckedBoxes(copyOfCheckBoxes);
  };

  /*const handleChartClick = (elementName, value) =>
  {
    if ( props.handleSetup !== undefined && props.bond !== undefined )
    {
      props.bond["operation"] = elementName;
      props.handleSetup(props.bond, elementName, value);
    }
  }*/

  const handleChartClick = (business_date) =>
  {
    console.log('Business Date ->>>>>', business_date )
    props.on_hist_business_date_change(business_date)
  }

    useEffect(() => {

      if ( checkBoxes !== undefined && props.curveDatesYields !== undefined )
      {
        var spread_set = []

        if ( tenorYields === undefined )
        {
          var tenor_yields = {}

          checkBoxes.map((box, index) => {
            tenor_yields[box['name']] = []
          });

            props.curveDatesYields['yields'].forEach((item, i) => {

              checkBoxes.map((box, index) => {
                tenor_yields[box['name']].push(item[box['name']])
              });

          });

          setTenorYields(tenor_yields)
        }

        checkBoxes.map((box, index) => {

          if ( box['checked'] == true )
          {
              console.log(box + " - " + index)
              spread_set.push(box['name'])
          }

        });

        console.log(spread_set)

        if (spread_set.length == 2  && tenorYields !== undefined )
        {
          var high_curve_point = tenorYields[spread_set[0]]
          var low_curve_point = tenorYields[spread_set[1]]

          var spreads = []
          var base_line = []

          if ( high_curve_point !== undefined && low_curve_point !== undefined )
          {
            high_curve_point.forEach((item, i) => {

              var spread = item - low_curve_point[i]
              spreads.push(spread)
              base_line.push(0.0)
            });

            const chart_data = {
              labels: props.curveDatesYields['dates'],
              datasets: [
                {
                  data : spreads,fill: false,
                  backgroundColor: colors[spread_set[0]],
                  borderColor: colors[spread_set[0]],
                  label : 'Spread: ' + spread_set[0] + '/' + spread_set[1],
                  pointStyle: false,
                  borderWidth: 1,
                  lineTension: 0.4
                }]
            };

            const options = {

              onClick: function(evt, element) {
                if(element.length > 0)
                {
                  var index = element[0].index
                  handleChartClick(props.curveDatesYields['int_dates'][index])
                }
              },

              plugins: {
                legend: {
                          display: false
                },
                title: {
                  display: true,
                  align: 'center',
                  text: 'Yield Spreads - ' + spread_set[0] + "/" + spread_set[1],
                  position: 'top',
                  color:"rgba(75,192,192,1)",
                },
              },
              elements: {
                     point:{
                         radius: 1.125
                     }
                 },
                 scales: {
       y: {
         grid: {
           drawBorder: false,
           lineWidth: ({ tick }) => tick.value == 0 ? 1 : 1,
           color: ({ tick }) => tick.value == 0 ? 'white':0
         }
       }
     }
            };

            setOptions(options);
            setData(chart_data);

          }



        }
        //console.log(props.curveDatesYields)
      }

    }, [checkBoxes, props.curveDatesYields, tenorYields]);

  return (
    <Container fluid>
        <Row>
          <Col style={{ display: 'flex', alignItems: 'center', paddingTop:'10px'}}>
            Spreads
          </Col>
          <Col>
         <div style={{ display: 'flex', alignItems: 'center', paddingTop:'15px' }}>
          {checkBoxes.map((box, index) => {
            return (
              <div className="ag-theme-balham-dark" >
              <Col style={{paddingLeft:'20px' }}>
                <input
                key={index}
                type="checkbox"
                name={box.name}
                onChange={handleCheckOnClick}
                checked={box.checked} /> {box.name}
              </Col>
          </div>
       );
     })}
     </div>
     </Col>
     </Row>
      <Row>
        <div style={{height:'30vh', paddingLeft:'10px', paddingRight:'10px'}}>
          <Line options={options} data={data} ref={chartRef} style={{height:'30vh', backgroundColor:'#192231', border:'1px solid #424242'}}/>
          </div>
      </Row>
    </Container>
    );
 });

export default SpreadsPanel;
